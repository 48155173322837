import {Component} from '@angular/core';

@Component({
    selector: 'app-loader',
    template: `
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 loader-wrapper">
                    <div class="spinner-grow" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        </div>`,
    styleUrls: ['loader.scss']
})

export class LoaderComponent {
}
