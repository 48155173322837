import {Component, OnInit} from '@angular/core';
import {PostService} from '../services/PostService';
import {Observable} from 'rxjs';
import {PostListItemDto} from '../services/dtos/PostListItemDto';
import {map} from 'rxjs/operators';
import * as _ from 'lodash';
import {animate, query, stagger, style, transition, trigger} from '@angular/animations';

@Component({
    selector: 'app-blog-list',
    templateUrl: 'blogList.html',
    animations: [
        trigger('postList', [
            transition(':enter', [
                query('.post-item', [
                    style({opacity: 0, transform: 'translateY(100px)'}),
                    stagger(-50, [
                        animate('500ms cubic-bezier(0.35, 0, 0.25, 1)', style({opacity: 1, transform: 'none'}))
                    ])
                ])
            ])
        ]),
    ],
    styleUrls: ['blogList.scss']
})

export class BlogListComponent implements OnInit {

    public posts: Observable<PostListItemDto[]>;

    constructor(private postService: PostService) {
    }

    ngOnInit() {
        this.posts = this.postService.findAll()
            .pipe(map((res) => this.setIsLikeDisabledForPosts(res)));
    }

    public likePost(post: PostListItemDto) {
        this.postService.likePost(post.id)
            .subscribe((res) => {
                if (res) {
                    post.likes = res.likes;
                    post.isLikeDisabled = true;
                }
            });
    }

    private setIsLikeDisabledForPosts(res) {
        const likedPosts = this.postService.getLikedPostsFromStorage();
        return _.map(res, (post: PostListItemDto) => {
            post.isLikeDisabled = Boolean(_.find(likedPosts, l => l.id === post.id));
            return post;
        });
    }
}
