import {Component, ViewEncapsulation} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Angulartics2GoogleAnalytics} from 'angulartics2/ga';

@Component({
    selector: 'app-root',
    templateUrl: './app.html',
    styleUrls: ['./app.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AppComponent {
    public title = 'codeforges';
    public langs = ['en', 'de'];
    public inverseHeader = false;
    constructor(private translateService: TranslateService,
                angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics) {
        translateService.setDefaultLang('en');
        angulartics2GoogleAnalytics.startTracking();
    }

    public changeLangTo(langKey: string): void {
        this.translateService.setDefaultLang(langKey);
    }

    public getCurrentLang(): string {
        return this.translateService.getDefaultLang();
    }
}
