import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-not-found',
    templateUrl: 'notFound.html'
})

export class NotFoundComponent implements OnInit {
    constructor() {
    }

    ngOnInit() {
    }
}
