import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class CaptchaService {
    private readonly URL = '/api/validate';

    constructor(private httpClient: HttpClient) {
    }

    public validate(captchaResponse: string): Observable<RecaptchaValidationResponse> {
        return this.httpClient.post(this.URL, {response: captchaResponse}) as Observable<RecaptchaValidationResponse>;
    }
}

export interface RecaptchaValidationResponse {
    success: boolean;
    challenge_ts: Date;
    hostname: string;
    'error-codes': any;
}
