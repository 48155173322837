import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BlogListComponent} from './list/BlogListComponent';
import {BlogDetailsComponent} from './details/BlogDetailsComponent';
import {BlogRouting} from './BlogRouting';
import {TranslateModule} from '@ngx-translate/core';
import {PostService} from './services/PostService';
import {HttpClientModule} from '@angular/common/http';
import {CommonModule} from '@angular/common';
import {LoaderModule} from '../common/loader/LoaderModule';
import {BlogComponent} from './BlogComponent';

@NgModule({
    imports: [
        CommonModule,
        LoaderModule,
        RouterModule.forChild(BlogRouting),
        HttpClientModule,
        TranslateModule,
    ],
    declarations: [
        BlogListComponent,
        BlogComponent,
        BlogDetailsComponent
    ],
    providers: [
        PostService
    ],
})
export class BlogModule {
}
