import {NgModule} from '@angular/core';
import {LandingPageComponent} from './LandingPageComponent';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {LandingPageRoutingModule} from './LandingPageRoutingModule';
import {HttpClientModule} from '@angular/common/http';
import {MatProgressSpinnerModule} from '@angular/material';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {Angulartics2Module} from 'angulartics2';
import {RECAPTCHA_SETTINGS, RecaptchaModule, RecaptchaSettings} from 'ng-recaptcha';
import {CaptchaService} from './services/CaptchaService';
import {captchaSettings} from '../../environments/environment';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MatProgressSpinnerModule,
        TranslateModule,
        HttpClientModule,
        BrowserAnimationsModule,
        Angulartics2Module,
        RecaptchaModule,
        LandingPageRoutingModule
    ],
    exports: [],
    declarations: [LandingPageComponent],
    providers: [
        CaptchaService,
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: {
                siteKey: captchaSettings.key,
            }as RecaptchaSettings,
        }
    ]
})
export class LandingPageModule {
}
