import {NgModule} from '@angular/core';
import {LoaderComponent} from './LoaderComponent';

@NgModule({
    imports: [],
    exports: [LoaderComponent],
    declarations: [LoaderComponent],
    providers: [],
})
export class LoaderModule {
}
