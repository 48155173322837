import {Route} from '@angular/router';
import {BlogListComponent} from './list/BlogListComponent';
import {BlogDetailsComponent} from './details/BlogDetailsComponent';
import {BlogComponent} from './BlogComponent';

export const BlogRouting: Route[] = [
    {
        path: 'blog',
        component: BlogComponent,
        children: [
            {path: 'list', component: BlogListComponent, data: {animation: 'isLeft'}},
            {path: 'details/:id', component: BlogDetailsComponent, data: {animation: 'isRight'}},
        ]
    }
];
