import {Injectable} from '@angular/core';
import {EMPTY, Observable} from 'rxjs';
import {PostDto} from './dtos/PostDto';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import * as _ from 'lodash';

@Injectable()
export class PostService {
    private readonly URL = 'https://codeforges-sandbox.herokuapp.com/api/post';
    private readonly LIKED_POSTS_KEY = 'LIKED_POSTS';

    constructor(private httpClient: HttpClient) {
    }

    public findAll(): Observable<PostDto[]> {
        return this.httpClient.get(this.URL, {params: {order: 'DESC'}}) as Observable<PostDto[]>;
    }

    public findBy(postId: string): Observable<PostDto> {
        return this.httpClient.get(this.URL + '/' + postId) as Observable<PostDto>;
    }

    public likePost(postId: number): Observable<{ likes: number }> {
        const likedPosts = this.getLikedPostsFromStorage();
        const hasLiked = _.find(likedPosts, (p) => p.id === postId);
        if (hasLiked) {
            return EMPTY;
        } else {
            return this.httpClient.put(this.URL + '/like/' + postId, {})
                .pipe(map((res: any[]) => {
                    likedPosts.push({id: postId});
                    localStorage.setItem(this.LIKED_POSTS_KEY, JSON.stringify(likedPosts));
                    return _.head(res);
                }));
        }
    }

    public getLikedPostsFromStorage(): any[] {
        return JSON.parse(localStorage.getItem(this.LIKED_POSTS_KEY)) || [];
    }
}
