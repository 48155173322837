import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import {AppComponent} from './AppComponent';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {LandingPageModule} from './landing/LandingPageModule';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NotFoundComponent} from './not-found/NotFoundComponent';
import {Angulartics2Module} from 'angulartics2';
import {PrivacyPolicyComponent} from './privacy-policy/PrivacyPolicyComponent';
import {BlogModule} from './blog/BlogModule';
import {RouterModule} from '@angular/router';
import {AppRouting} from './AppRouting';
import {AppCommonModule} from './common/AppCommonModule';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        NotFoundComponent,
        PrivacyPolicyComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppCommonModule,
        RouterModule.forRoot(AppRouting),
        BlogModule,
        FormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        Angulartics2Module.forRoot(),
        BrowserAnimationsModule,
        LandingPageModule
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
