import {Component, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {animate, style, transition, trigger} from '@angular/animations';
import {HttpClient} from '@angular/common/http';
import {finalize} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {Angulartics2} from 'angulartics2';
import {CaptchaService} from './services/CaptchaService';

declare var $;
declare var Typed;
declare var gtag_report_conversion;

@Component({
    selector: 'app-landing',
    templateUrl: 'landingPage.html',
    animations: [
        trigger(
            'enterAnimation', [
                transition(':enter', [
                    style({transform: 'translateX(100%)', opacity: 0}),
                    animate('250ms', style({transform: 'translateX(0)', opacity: 1}))
                ]),
                transition(':leave', [
                    style({transform: 'translateX(0)', opacity: 1}),
                    animate('250ms', style({transform: 'translateX(100%)', opacity: 0}))
                ])
            ]
        )
    ],
})

export class LandingPageComponent implements OnInit {
    public isFormSubmitted = false;
    public client: Client = {} as Client;
    public isLoading = false;
    public emailSubmitted = false;

    private readonly API_QUOTE = '/api/quote';

    constructor(private httpClient: HttpClient,
                private angulartics2: Angulartics2,
                private reCaptchaService: CaptchaService,
                private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.route.fragment.subscribe((fra) => {
            if (fra) {
                document.querySelector('#' + fra).scrollIntoView({behavior: 'smooth'});
            }
        });
        $(document).ready(() => {
            if ($('.typed').length) {
                const typed = new Typed('.typed', {
                    strings: ['Healthcare', 'Fintech', 'Real Estate', 'e-Commerce'],
                    typeSpeed: 150,
                    backDelay: 500,
                    backSpeed: 50,
                    loop: true
                });
            }
        });
    }

    public getAQuote() {
        if (!this.route.snapshot.fragment) {
            return;
        }
        this.angulartics2.eventTrack.next(
            {
                action: 'getQuoteClick',
                properties: {
                    category: 'GetAQuote',
                    label: 'getQuoteClick',
                    value: 1
                }
            }
        );
        document.querySelector('#' + this.route.snapshot.fragment).scrollIntoView({behavior: 'smooth'});
    }

    public onCaptcha($event) {
        this.reCaptchaService.validate($event)
            .subscribe((res) => {
                if (res.success) {
                    this.sendEmail();
                }
            });
    }

    public submitForm(form: NgForm) {
        if (form.valid) {
            this.emailSubmitted = true;
        }
    }

    private sendEmail() {
        this.isLoading = true;
        this.sendQuoteRequest(this.client)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe(() => {
                this.isFormSubmitted = true;
                gtag_report_conversion();
                this.angulartics2.eventTrack.next(
                    {
                        action: 'submitEmail',
                        properties: {
                            category: 'GetAQuote',
                            label: 'SubmitEmail',
                            value: 1
                        }
                    }
                );
            });
    }

    private sendQuoteRequest(client: Client) {
        return this.httpClient.post(this.API_QUOTE, {
            email: client.email,
            description: client.description,
            website: 'main'
        });
    }
}


interface Client {
    email: string;
    description: string;
    website: string;
}
