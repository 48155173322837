import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-blog',
    templateUrl: 'blog.html',
    styleUrls: ['blog.scss']
})

export class BlogComponent implements OnInit {
    ngOnInit() {
    }
}
