import {Component, OnInit} from '@angular/core';
import {PostService} from '../services/PostService';
import {PostDto} from '../services/dtos/PostDto';
import {Observable} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {animate, query, stagger, style, transition, trigger} from '@angular/animations';

@Component({
    selector: 'app-blog-details',
    templateUrl: 'blogDetails.html',
    animations: [
        trigger('postDetails',
            [
                transition(':enter', [
                    query('.container', [
                        style({opacity: 0, transform: 'translateY(100px)'}),
                        stagger(-50, [
                            animate('500ms cubic-bezier(0.35, 0, 0.25, 1)', style({opacity: 1, transform: 'none'}))
                        ])
                    ])
                ]),
            ])

    ]
})

export class BlogDetailsComponent implements OnInit {
    public postDetails: Observable<PostDto>;

    constructor(private postService: PostService,
                private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.route.params.subscribe((params) => {
            if (params.id) {
                this.postDetails = this.postService.findBy(params.id);
            }
        });
    }
}
