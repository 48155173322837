import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LocalizeRouterModule} from 'localize-router';
import {LandingPageComponent} from './LandingPageComponent';

const landingRoutes: Routes = [
    {path: '', component: LandingPageComponent}
];

@NgModule({
    imports: [
        RouterModule.forChild(landingRoutes),
        LocalizeRouterModule.forChild(landingRoutes)
    ],
    exports: [RouterModule, LocalizeRouterModule]
})
export class LandingPageRoutingModule {
}
